import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import FontSize from '@config/theme/definitions/fontSize'

import { makeStyles } from '@material-ui/core/styles'

import MetaTags from '@objects/metatags'
import Container from '@objects/container'
import Button from '@objects/button'
import Headline from '@objects/headline'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Icon from '@objects/icon'
import InfoBox from '@components/infoBox'
import TeaserRow from '@components/teaserRow'
import useGlobalText from '@hooks/useGlobalText'

interface IRootIndex {
  data: Record<string, unknown>
  pageContext: Record<string, string>
  searchIndex: RvG.PageHelpers.SearchIndex
}

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      marginTop: theme.spacing(15),
    },
    contentRoot: {
      textAlign: 'center',
      paddingTop: theme.spacing(9),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(20),
      },
    },
    copy: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(12.5),
    },
    headerIcon: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      marginBottom: theme.spacing(8),
      '& svg': {
        height: 'auto',
        width: '123px',
      },
    },
    underlinedBtn: {
      borderRadius: 0,
      padding: theme.spacing(0, 0, 1),
      fontSize: FontSize['base'],
      '& svg': {
        height: FontSize['3lg'],
        width: 'auto',
      },
    },
    centeredBtn: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    infoBox: {
      textAlign: 'left',
      maxWidth: '704px',
    },
  })
)

export default function RootIndex({ pageContext }: IRootIndex): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const { siteSearchIndex, teaserRow } = useStaticQuery(graphql`
    query {
      siteSearchIndex {
        index
      }
      teaserRow: contentfulModuleTeaserRow(
        titleInternal: { eq: "Weitere interessante Seiteninhalte" }
      ) {
        headline
        relatedContent {
          ... on ContentfulGenericPage {
            fields {
              fullPath
            }
            teaserCopy {
              raw
            }
            teaserImage {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            teaserTitle
          }
          ... on ContentfulArticlePage {
            fields {
              fullPath
            }
            teaserCopy {
              raw
            }
            teaserImage {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            teaserTitle
          }
        }
      }
    }
  `)

  const infoHeadline = 'Wieso konnte diese Seite nicht gefunden werden?'
  const infoCopy = {
    raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Wenn Sie einen Link aus Ihren Favoriten oder Lesezeichen angeklickt haben, ist dieser möglicher Weise veraltet.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Wenn Sie einen Link von einer fremden Seite angeklickt haben, ist dieser womöglich fehlerhaft oder veraltet.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Wenn Sie die Internetadresse direkt in die Adresszeile Ihres Browsers eingegeben haben, könnte ein Tippfehler die Ursache sein. Bitte überprüfen Sie Ihre Eingabe.","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
  }

  return (
    <>
      <Container className={classes.contentRoot}>
        <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />

        <div className={classes.headerIcon}>
          <Icon name={'NotFound'} viewBox={'0 0 129 86'} />
        </div>
        <Headline level={1} className={classes.headline}>
          <FormattedMessage id={'page.404.copy'} values={{ break: <br /> }} />
        </Headline>
        <Button
          className={clsx(classes.underlinedBtn, classes.centeredBtn)}
          type={'underlined'}
          to={'/'}
        >
          <FormattedMessage id={'basket.checkout.thanks.home'} />
        </Button>

        {/*  <Button
        className={clsx(classes.underlinedBtn, classes.centeredBtn)}
        type={'underlined'}
        //to={'/'}
        onClick={() => showOverlay(true)}
      >
        <FormattedMessage id={'search.content'} />
      </Button> */}

        {/* <Search className={classes.search} searchIndex={searchIndex} /> */}

        <InfoBox
          className={classes.infoBox}
          type="InfoBox"
          headline={infoHeadline}
          copy={infoCopy}
        />
      </Container>
      {teaserRow?.relatedContent?.length > 0 && (
        <TeaserRow
          type="Default"
          theme="Dark"
          headline={
            teaserRow.headline || getText('relatedContentDefaultHeadline')
          }
          relatedContent={teaserRow.relatedContent}
        />
      )}
    </>
  )
}
